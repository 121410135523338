/* OVERRIDES */

// Colors

$gray-800: rgb(0 0 0 / 75%);

// Cards

$card-height: 100%;

// Input

$input-bg: white;

// Navs

$nav-tabs-link-active-bg: transparent;

// Pagination

$pagination-bg: transparent;
$pagination-hover-color: black;
$pagination-hover-bg: white;
$pagination-active-bg: #2c2c2c;

@import "~bootswatch/dist/darkly/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/darkly/bootswatch";

.accordion-button:not(.collapsed) {
  color: #ffe400;
  background-color: inherit;
}

.modal-content {
  backdrop-filter: blur(10px);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  text-decoration-line: line-through;
  text-decoration-color: #ffe400;
}

// a {
//   border-bottom: 1px solid #ffe400;
//   box-shadow: 0px -4px 0px #ffe400 inset;
// }
