@media (min-width: 767px) {
  .blog_post__block-paragraph {
    width: 90%;
    margin: 0 auto;
  }
}

.blog_post__block-quote p {
  font-size: 1.25em;
}

.blog_post__block-image img {
  max-width: 100%;
  height: auto;
}

.blog_post__block-image .full-width img {
  width: 100%;
}

.blog_post__block-image .full-width,
.blog_post__block-image .half-width {
  margin: 0 auto;
}

.blog_post__block-image .pull-right {
  margin-left: 10px;
}

.blog_post__block-image .pull-left {
  margin-right: 10px;
}

.blog_post__block-image,
.blog_post__block-embed,
.blog_post__block-media {
  margin-bottom: 1em;
}
