@import url("./BlogPost.css");
@import url("./nprogress.css");

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 7rem;
}

a {
  color: inherit;
  text-decoration: none;
}

a.neutral-color {
  color: inherit;
}

a:hover {
  text-decoration: underline;
  text-decoration-color: #ffe400;
  color: inherit;
}

/* FOOTER */

.footer {
  background-color: rgba(0, 0, 0, 0.5);
}

.navbar-nav .nav-link {
  font-weight: bold;
}

.navbar-dark .navbar-nav .nav-link.active {
  background: linear-gradient(90deg, #ffe400, #ffe400) 0 100% no-repeat;
  background-size: 100% 3px;
}

blockquote {
  padding: 10.5px 21px;
  margin: 0 0 21px;
  font-size: 18.75px;
  border-left: 5px solid #dddddd;
  border-left-width: 1px;
  color: #6f6f6f;
}

/* MEDIA */

.media-body {
  color: #969696;
}

.media-list > li:nth-child(even) {
  background-color: #191919;
}

.media-list > li:nth-child(even) {
  background-color: #dadbdf;
}

/* OLD GLOBALS */

/* .btn {
  background: #ffe400;
  border: none;
  border-radius: 5px;
  color: #222222;
}

.btn:hover {
  background: white;
  color: #222222;
} */

.mailing-list {
  margin-top: 50px;
  margin-bottom: 20px;
}

.mailing-list input:focus {
  outline-style: none;
}

#mce-EMAIL {
  border: 1px solid #303030;
  height: 30px;
  vertical-align: middle;
}

#mc-embedded-subscribe {
  background-color: #ffe400;
  height: 30px;
  width: 100px;
  border: none;
  margin-left: 10px;
  border-radius: 5px 5px;
  vertical-align: middle;
}

#mc-embedded-subscribe:hover {
  background: white;
}

.carousel-inner > .item > img {
  width: 100%;
}

.carousel-inner {
  min-height: 300px;
  max-height: 750px;
}

.null-link {
  color: white;
  padding: 3px;
  margin-top: 9px;
  margin-left: 5px;
}

.show-banner {
  width: 900px;
  margin: 0 auto;
  margin-bottom: 30px;
}

#dj-name-label {
  display: inline;
}

.show-detail-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.show-detail-table > thead > tr > th,
.show-detail-table > tbody > tr > th,
.show-detail-table > tfoot > tr > th,
.show-detail-table > thead > tr > td,
.show-detail-table > tbody > tr > td,
.show-detail-table > tfoot > tr > td {
  padding: 8px 0;
  line-height: 1.42857143;
  vertical-align: top;
}

.show-detail-table > thead > tr > th {
  vertical-align: bottom;
}

#blog-album {
  width: 40%;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
}

.blog-album-blur {
  top: -50%;
  filter: blur(100px);
}

/*!* don't allow browser text-selection *!*/
.dj-img {
  margin-bottom: 50px;
  border: 5px solid white;
}

.dj-text {
  display: inline-block;
}

#login-btn {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 0;
}

#form-h2 {
  margin-left: -5px;
}

.login-label {
  display: inline;
  color: #969696;
  font-family: "Open Sans", sans-serif;
}

.commentbox-btn {
  margin-left: 54.5% !important;
}

@media (max-width: 768px) {
  .commentbox-btn {
    margin-left: 45% !important;
  }
}

#form-submit-commentbox {
  width: 80px;
}

#top-about-ad,
#lower-about-ads {
  text-align: center;
}

#top-about-ad,
#mid-about-ad,
#bottom-about-ad {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #top-about-ad,
  #mid-about-ad,
  #bottom-about-ad {
    margin-left: 20px;
  }

  #top-about-ad,
  #lower-about-ads {
    float: right;
  }
}

#banner-ad {
  margin-bottom: 18px;
}

@media screen and (max-width: 992px) { 
  #banner-ad {
    transform: translate(-2%) scale(0.91);
  }
}

@media screen and (max-width: 768px) { 
  #banner-ad {
    width: 120%; 
    transform: translate(-14.5%) scale(0.705);
  }
}

@media screen and (max-width: 600px) { 
  #banner-ad {
    width: 200%; 
    transform: translate(-25%) scale(0.51);
  }
}

.dfp-tag-wrapper iframe {
  -webkit-box-shadow: 0 0 6px #666;
  -moz-box-shadow: 0 0 6px #666;
  -o-box-shadow: 0 0 6px #666;
  box-shadow: 0 0 6px #666;
}

body,
a,
p,
h2 {
  font-family: "Open Sans", Helvetica, sans-serif !important;
}

h1,
h3,
h4,
.main a,
#post-read-more,
#post-title,
.panel-body,
thead tr th,
legend,
h2 {
  color: #dadbdf;
}

.main p,
.dj-bio,
a p,
tbody tr,
.control-label,
.modal-body p,
.modal .close {
  color: #969696;
}

.dropdown-menu {
  color: #dadbdf !important;
  background: #121212 !important;
}

input {
  color: #303030;
}

li.divider {
  background-color: #dadbdf2e;
}
