.navbar {
  /* box-shadow: inset 1px 1px rgb(255 255 255 / 20%), inset -1px -1px rgb(255 255 255 / 10%), 1px 3px 24px -1px rgb(0 0 0 / 15%); */
  /* background-color: transparent; */
  background-color: rgba(0, 0, 0, 0.5);
  /* background-image: linear-gradient(
125deg
, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.2) 70%); */
  backdrop-filter: blur(5px);
  /* transition: 0.75s; */
  /* overflow: hidden; */
}
